<template>
  <div class="dropdown-menu">
    <div class="dropdown-item" v-show="canSearch">
      <input
        type="text"
        class="form-control form-control-sm"
        v-model="search"
        placeholder="buscar ..."
        @change="getList()"
      />
    </div>
    <div class="dropdown-divider" v-show="canSearch"></div>
    <a
      href="#"
      class="dropdown-item"
      v-for="l in list"
      :key="l.id"
      @click.prevent="$emit('setEmployee', l.id)"
      >{{ l.name }}
    </a>
  </div>
</template>
<script>
import { AdminService } from "../AdminService";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    specialties: { default: "" }
  },

  data: () => ({
    search: "",
    canSearch: process.env.VUE_APP_CAN_SEARCH_DOCTOR || false,
    list: []
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    this.getList();
  },

  methods: {
    gl() {
      this.getList();
    },
    getList() {
      AdminService.getEmployees({
        search: this.search,
        specialties: this.specialties
      }).then(res => {
        this.list = res.data;
      });
    }
  }
};
</script>

<style></style>
