<template>
  <td
    ref="el"
    class="dropdown p-0 border app_td"
    :class="{ app_td_shadow: currentQuote.id }"
    :rowspan="currentQuote.intervals"
    v-quote-bg-color="currentQuote.status_id"
  >
    <i
      v-show="unitTime.overQuotes > 0"
      class="fa fa-bolt pull-right app_td_q_light"
      data-session-id="43606"
    ></i>
    <div
      v-show="unitTime.disabled"
      class="m-0 rounded-0 p-1 alert alert-secondary disabled text-right"
    >
      <a href="javascript:void(0)" :title="unitTime.disableExcuse">
        <span v-if="config_agenda.show_excuse">
          {{ unitTime.disableExcuse }}
        </span>
        <i class="fas fa-comment" v-else></i>
      </a>
      <a
        class="ml-2"
        href="javascript:void(0)"
        @click.prevent="$emit('showToEnable', unitTime.datetime)"
        v-show="config.delete_disable_times"
      >
        <i class="fa fa-trash"></i>
      </a>
    </div>

    <div class="app_td_div_nowrap">
      <a
        href="javascript:void(0)"
        v-show="unitTime.free && unitTime.quotes == 0"
        :class="{ 'd-block': unitTime.free && unitTime.quotes == 0 }"
        class="alert alert-success-phantom text-right m-0 rounded-0 p-1"
        @click.prevent="showMenu()"
        @blur="hideMenu()"
      >
        <i class="fa fa-plus-circle"></i>
      </a>
      <a
        href="javascript:void(0)"
        v-if="currentQuote.id"
        :title="currentQuote.client_name"
        class="alert m-0 rounded-0 p-1 d-block text-reset"
        @click.prevent="showMenu()"
        @blur="hideMenu()"
      >
        <small>{{ currentQuote.client_name }}</small>
      </a>

      <div
        class="dropdown-menu"
        ref="elMenu"
        aria-labelledby="dropdownMenuButton"
      >
        <template v-if="unitTime.quotes == 0">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click.prevent="$emit('makeQuote', getDate())"
            >Dar Cita</a
          >
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click.prevent="$emit('disableTime', getDate())"
            v-show="config.add_disable_times"
            >Bloquear</a
          >
        </template>
        <template v-else>
          <router-link
            v-show="unitTime.firstQuote.treatment_plan_id"
            :to="
              `/dental-clinic/patients/${unitTime.firstQuote.client_id}/treatment-plans/${unitTime.firstQuote.treatment_plan_id}`
            "
            class="dropdown-item"
          >
            Ir al Plan
          </router-link>
          <router-link
            :to="`/dental-clinic/patients/${currentQuote.client_id}/to-pay`"
            class="dropdown-item"
          >
            Recaudacion
          </router-link>
          <router-link
            :to="`/dental-clinic/patients/${currentQuote.client_id}/profile`"
            class="dropdown-item"
          >
            Datos personales
          </router-link>
          <router-link
            v-show="currentQuote.specialty_id == 3"
            :to="
              `/dental-clinic/patients/${unitTime.firstQuote.client_id}/orthodontics/${unitTime.firstQuote.treatment_plan_id}/program-quotes`
            "
            class="dropdown-item"
          >
            Control de Ortodoncia
          </router-link>
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click.prevent="$emit('changeDate', currentQuote)"
            :class="{'disabled': !hasPermission('srv-quotes-change-status')}"
          >
            Cambiar fecha
          </a>
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click.prevent="$emit('changeDuration', currentQuote)"
          >
            Modificar duracion
          </a>
          <!-- <a class="dropdown-item" href="javascript:void(0)"
            >Agregar Comentario</a
          > -->
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click.prevent="$emit('changeState', currentQuote)"
          :class="{'disabled': !hasPermission('srv-quotes-change-status')}"
          >
            Cambiar estado
          </a>
        </template>
      </div>
    </div>
    <comment
      class="app_comment"
      v-if="currentQuote.id"
      :comment="currentQuote.commentary"
      :quote_id="currentQuote.id"
    ></comment>
  </td>
</template>
<script>
import { myMoment } from "src/utils/myMoment";
import { mapState } from "vuex";
import comment from "./Comments";
import { quoteBgColor } from "./quote-bg-color-directive";
import hasPermissionMixin from "src/lt/utils/hasPermissionMixin.js";

export default {
  mixins: [hasPermissionMixin],

  components: {
    comment
  },

  directives: {
    quoteBgColor
  },

  // filters

  props: {
    unitTime: {
      default: () => ({
        isFake: true,
        disabled: null,
        free: null,
        quotes: 0,
        datetime: null,
        firstQuote: {
          id: null,
          intervals: 1,
          status_id: null
        },
        overQuotes: 0
      })
    },
    timeOfDay: {},
    dayOfWeek: {},
    currentTime: {}
  },

  data: () => ({
    mountMenus: false
  }),

  computed: {
    currentQuote() {
      return this.unitTime.firstQuote || {};
    },
    ...mapState({
      config: (s) => s.config.admin.employee_schedule,
      config_agenda: (s) => s.config.dental.agenda
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    hideMenu() {
      setTimeout(() => {
        this.$refs.elMenu.classList.remove("d-inline");
      }, 200);
    },
    showMenu() {
      this.$refs.elMenu.classList.add("d-inline");
    },
    getDate() {
      return myMoment(this.unitTime.datetime).toDate();
    },
    handlerClick() {
      if (this.unitTime.free || this.currentQuote.id) {
        this.showMenu();
      }
    }
  }
};
</script>

<style scoped>
.app_dt_no_arrow:after {
  display: none;
}

.app_td {
  max-height: 34px;
}

.app_td_div_nowrap {
  height: 100%;
  width: 139px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* overflow-y: auto; */
}

.app_comment {
  position: absolute;
  right: 0.2rem;
  top: 0.2rem;
}

.app_td_shadow {
  -webkit-box-shadow: inset 0 0.225rem 0.225rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 0.225rem 0.225rem rgba(0, 0, 0, 0.075) !important;
}

.app_td_q_light {
  margin-right: 0px;
  color: #fff;
  font-size: 9px;
  margin-top: 4px;
  position: absolute;
  left: -5px;
  top: -5px;
  background: rgb(255, 112, 0);
  padding: 2px 2px;
  z-index: 1;
  border-radius: 4px;
}

.alert {
  font-size: 1rem;
}

.alert-success-phantom {
  color: #155724;
  background-color: #d4edda;
}
</style>
