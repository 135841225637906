export const isAToothPiece = function(piece) {
  let nPiece = parseInt(piece);
  return nPiece > 10 && nPiece <= 90;
};

export const isHigher = function(piece) {
  let nPiece = parseInt(piece);
  return (nPiece > 10 && nPiece < 30) || (nPiece > 50 && nPiece < 70);
};

export const isLower = function(piece) {
  let nPiece = parseInt(piece);
  return (nPiece > 30 && nPiece < 50) || (nPiece > 70 && nPiece < 90);
};

export const pieceName = function(piece) {
  let nPiece = parseInt(piece ? piece : 0);

  switch (nPiece) {
    case 101:
      return "Arcada Sup.";
    case 102:
      return "Arcada Inf.";
    case 0:
      return "Todos";
    default:
      return nPiece.toString();
  }
};

export const isToothPiece = function(piece) {
  let nPiece = parseInt(piece);
  if (
    (nPiece >= 11 && nPiece <= 18) ||
    (nPiece >= 21 && nPiece <= 28) ||
    (nPiece >= 31 && nPiece <= 38) ||
    (nPiece >= 41 && nPiece <= 48) ||
    (nPiece >= 51 && nPiece <= 55) ||
    (nPiece >= 61 && nPiece <= 65) ||
    (nPiece >= 71 && nPiece <= 75) ||
    (nPiece >= 81 && nPiece <= 85)
  ) {
    return true;
  }
  return false;
};

export const listFaces = function(l) {
  let list = [];
  if (l.overhall > 0) {
    return ["Pieza completa"];
  }
  if (l.lingual > 0) {
    list.push("Lingual");
  }
  if (l.palatino > 0) {
    list.push("Palatino");
  }
  if (l.distal > 0) {
    list.push("Distal");
  }
  if (l.mesial > 0) {
    list.push("Mesial");
  }
  if (l.oclusal > 0) {
    list.push("Oclusal");
  }
  if (l.vestibular > 0) {
    list.push("Vestibular");
  }
  return list;
};

export const strTeethFaces = function(l) {
  let list = listFaces(l);
  let str_val = "";
  list.map(x => {
    str_val += x + ", ";
  });
  return str_val;
};

export const isLeftTooth = function(piece) {
  if (
    (piece >= 10 && piece < 20) ||
    (piece >= 40 && piece < 50) ||
    ((piece >= 50 && piece < 60) || (piece >= 80 && piece < 90))
  ) {
    return true;
  } else return false;
};

export const getFaceFromPieceBoxCord = function(piece, boxCord) {
  if (boxCord == 22) return "oclusal";
  if (boxCord == 12) {
    return isHigher(piece) ? "vestibular" : "lingual";
  }
  if (boxCord == 21) {
    return isLeftTooth(piece) ? "distal" : "mesial";
  }
  if (boxCord == 23) {
    return isLeftTooth(piece) ? "mesial" : "distal";
  }
  if (boxCord == 32) {
    return isHigher(piece) ? "palatino" : "vestibular";
  }
};
