<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light bg-light p-1">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link mr-5" href="javascript:void(0)">
              <span class="btn btn-primary btn-sm">
                <b>{{ quotes }} Citas </b>
              </span>
            </a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/dental-clinic/agenda">
              <i class="fa fa-list"></i> Diaria
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-columns "></i> Semanal
            </a>
            <employee-search-dropdown-menu
              :specialties="specialty_id"
              @setEmployee="
                $router.push({
                  path: '/dental-clinic/weekly-agenda/' + $event,
                  query: { ...$route.query, date: undefined }
                })
              "
            ></employee-search-dropdown-menu>
          </li>
        </ul>
        <ul class="navbar-nav my-2 my-lg-0">
          <li class="nav-item dropdown">
            <a
              class="shadow-sm btn btn-sm btn-light ml-1 dropdown-toggle"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-print"></i> Imprimir
            </a>
            <employee-search-dropdown-menu
              :specialties="specialty_id"
              @setEmployee="
                $router.push({
                  path: '/dental-clinic/agenda/to-print',
                  query: {
                    employeeId: $event,
                    date: $route.query.date || today()
                  }
                })
              "
            ></employee-search-dropdown-menu>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
import employeeSearchDropdownMenu from "../../admin-module/employees/search-dropdown-menu";
import { mapState } from "vuex";
import { myMoment, momentDateFormat } from "../../utils/myMoment";

export default {
  components: {
    employeeSearchDropdownMenu
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapState({
      quotes: (s) => s.dental.quotes,
      specialty_id: (s) => s.config.dental.treatments.specialty_id
    })
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    today() {
      return myMoment().format(momentDateFormat);
    }
  }
};
</script>

<style></style>
