<template>
  <div>
    <select-duration
      class="mb-2"
      :interval="interval"
      :iniTime="quote.ini_date"
      :employeeId="quote.employee_id"
      v-model="duration"
      :quoteId="quote.id"
    ></select-duration>
    <app-button-submit @click="save()"></app-button-submit>
  </div>
</template>
<script>
import selectDuration from "./select-duration";
import { DentalClinicService } from "../service";
import { myMoment, momentDateTimeFormat } from "../../utils/myMoment";

export default {
  components: {
    selectDuration
  },

  // directives
  // filters

  props: {
    interval: {},
    // quoteId: {}
  },

  data: () => ({
    quote: {},
    duration: 0
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    load(quote) {
      this.reset();
      this.quote = quote;
    },
    reset() {
      this.duration = 0;
    },
    save() {
      DentalClinicService.saveQuote({
        change_duration: 1,
        id: this.quote.id,
        ini_date: this.quote.ini_date,
        interval: this.interval,
        end_date: myMoment(this.quote.ini_date)
          .add(this.duration, "minute")
          .format(momentDateTimeFormat)
      }).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style></style>
