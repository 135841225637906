<template>
  <div class="table-responsive">
    <table class="table table-sm table-bordered">
      <thead>
        <app-tr-weekpicker
          :dayToBegin="1"
          ref="weekPicker"
          @changeWeek="setCurrentTime($event, false)"
        ></app-tr-weekpicker>
      </thead>
      <tbody>
        <tr v-show="loading">
          <td></td>
          <td colspan="8">
            <i class="fas fa-spinner fa-spin fa-fw"></i> Cargando
          </td>
        </tr>
        <tr v-for="(l, i) in day_times" :key="i">
          <td class="text-center">{{ l.time }}</td>
          <template v-for="l1 in [1, 2, 3, 4, 5, 6, 0]">
            <td-week-form
              style="display: table-cell;"
              :key="l1"
              v-if="!times[`${l1}_${l.time}`] || times[`${l1}_${l.time}`].mount"
              :unitTime="times[`${l1}_${l.time}`]"
              :timeOfDay="l.time"
              :dayOfWeek="l1"
              :currentTime="currentTime"
              @disableTime="disableTime($event)"
              @changeState="
                $refs.elChangeSate.show();
                quote = $event;
              "
              @showToEnable="
                $refs.elModalEnableTime.show();
                $refs.elModalEnableTime.val = $event;
              "
              @makeQuote="
                $refs.elModalQuoteForm.show();
                $refs.elMiniForm.setIniTime($event);
              "
              @changeDuration="
                $refs.elModalChangeDuration.show();
                $refs.elChangeDuration.load($event);
              "
              @changeDate="
                $refs.elModalChangeDate.show();
                $refs.elChangeDate.load($event);
              "
            >
              <template slot="menu" scope="{ quote }">
                <a
                  href="#"
                  class="dropdown-item"
                  @click.prevent="$emit('ortControl', quote)"
                  v-show="quote.specialty_id == 3"
                >
                  Control de Ortodoncia
                </a>
              </template>
            </td-week-form>
          </template>
          <td class="text-center">{{ l.time }}</td>
        </tr>
      </tbody>
    </table>

    <app-modal-basic ref="elModalChangeDuration">
      <change-duration
        :interval="unit_time"
        ref="elChangeDuration"
        @submitted="
          $refs.elModalChangeDuration.hide();
          reload();
        "
      ></change-duration>
    </app-modal-basic>

    <app-modal-yn
      ref="elModalEnableTime"
      @yes="enableTime($refs.elModalEnableTime.val)"
    >
      <p>Reactivar este intervalo de tiempo?</p>
    </app-modal-yn>

    <app-modal-basic ref="elModalQuoteForm">
      <h4>AGENDAR</h4>
      <mini-form
        ref="elMiniForm"
        :interval="unit_time"
        :employee_id="employee_id"
        :group="overLoad ? 2 : 1"
        @submitted="
          $refs.elModalQuoteForm.hide();
          reload();
        "
      ></mini-form>
    </app-modal-basic>

    <app-modal-basic ref="elChangeSate">
      <select-status
        :value="quote.status_id"
        :lastChangeTime="quote.updated_at"
        @input="
          quote.status_id = $event;
          saveQuote();
        "
      ></select-status>
    </app-modal-basic>

    <app-modal-basic ref="elModalDisableTime">
      <disable-time
        ref="elDisableTime"
        :employee_id="employee_id"
        :interval="unit_time"
        @submitted="
          $refs.elModalDisableTime.hide();
          reload();
        "
      ></disable-time>
    </app-modal-basic>

    <app-modal-basic ref="elModalChangeDate" :xl="true">
      <change-date
        ref="elChangeDate"
        :interval="unit_time"
        :group="overLoad ? 2 : 1"
        @submitted="
          $refs.elModalChangeDate.hide();
          reload();
        "
      ></change-date>
    </app-modal-basic>

    <!-- <week-form-menu ref="elMenu"></week-form-menu> -->
  </div>
</template>
<script>
import { myMoment, momentDateFormat } from "../../utils/myMoment";
import { DentalClinicService } from "../service";
import { mapState } from "vuex";
import { ServiceFactory } from "../../service-module/service";
import { dayTimes } from "../../base-module/day-times";
import miniForm from "./mini-form";
import tdWeekForm from "./td-week-form.vue";
// import $ from "jquery";
import DisableTime from "./disable-time.vue";
// import weekFormMenu from "./week-form-menu";
import selectStatus from "../../service-module/quotes/SelectStatus";
import changeDate from "./change-date";
import changeDuration from "./change-duration";
import { routeMix } from "../../base-module";
// import quoteForm from "./Form";

export default {
  mixins: [routeMix],
  components: {
    miniForm,
    tdWeekForm,
    DisableTime,
    // weekFormMenu,
    selectStatus,
    changeDate,
    changeDuration
    // quoteForm
  },

  // directives
  // filters

  props: {
    employee_id: {},
    overLoad: {}
  },

  data: () => ({
    currentTime: myMoment(),
    unit_time: 60,
    day_times: [],
    times: {},
    quote: {},
    timeToBegin: "",
    timeToEnd: "",
    loading: false
  }),

  computed: {
    ...mapState({
      defaultTimeToBegin: (s) => s.config.dental.employees.hour_to_begin
    })
  },

  watch: {
    employee_id() {
      this.getConfig();
    },
    overLoad() {
      this.reload();
    }
  },

  created() {},

  mounted() {
    if (this.$route.query.date) {
      let timeToIn = myMoment(this.$route.query.date);
      this.$refs.weekPicker.setTime(timeToIn);
      this.currentTime = timeToIn;
    }
  },

  methods: {
    enableTime(datetime) {
      DentalClinicService.disableScheduleTime({
        enableTime: 1,
        employee_id: this.employee_id,
        datetime: datetime,
        duration: this.unit_time
      }).then(() => {
        this.$refs.elModalEnableTime.hide();
        this.reload();
      });
    },
    saveQuote() {
      DentalClinicService.saveQuote({
        ...this.quote,
        update_status: 1
      });
    },
    reload() {
      this.getTimes();
    },
    disableTime(e) {
      this.$refs.elModalDisableTime.show();
      this.$refs.elDisableTime.setIniTime(e);
    },
    // getDateFromWeekDay() {
    // // },
    // showFreeMenu({ datetime, el }) {

    //   // this.$refs.elFreeMenu
    // },
    setCurrentTime(newTime = new Date(), external = true) {
      if (external) {
        this.$refs.weekPicker.setDate(myMoment(newTime).format(momentDateFormat));
      }
      this.currentTime = myMoment(newTime);
      this.getConfig();
      this.routeMixAddQueryParam(
        "date",
        myMoment(newTime).format(momentDateFormat)
      );
      this.$emit("changeTime", newTime);
    },
    darCita(dayOfWeek, utime) {
      var oFecha = myMoment("00", "HH");
      oFecha.add(-oFecha.day() + dayOfWeek, "days");
      this.$refs.miniform.setIniTime(oFecha.format("YYYY/MM/DD") + " " + utime);
      this.$refs.dmf.show();
    },
    getConfig() {
      DentalClinicService.getEmployeeConfig(this.employee_id).then((res) => {
        this.unit_time = res.interval_service;
        this.getTimes().then(() => {
          this.updateTimes();
        });
      });
    },
    reset() {
      this.times = {};
      // this.day_times = [];
    },
    async getTimes() {
      this.loading = true;
      this.reset();
      let list = await ServiceFactory.scheduleTimes({
        employee_id: this.employee_id,
        interval: this.unit_time,
        ini_date: this.$refs.weekPicker.getBeginDate(),
        end_date: this.$refs.weekPicker.getLastDate(),
        group: this.overLoad ? 2 : 1
      });
      this.loading = false;

      let times = {};
      let timeToBegins = "23:59";
      let timeToEnd = "00:00";
      let quotes = 0;

      for (let l of list) {
        if (l.time < timeToBegins && l.time) {
          timeToBegins = l.time;
        }
        if (l.time > timeToEnd && l.time) {
          timeToEnd = l.time;
        }
        times[`${l.dayOfWeek}_${l.time.substring(0, 5)}`] = l;
        quotes += l.mount ? parseInt(l.quotes) : 0;
      }
      // console.log(quotes, timeToEnd);
      this.$store.commit("dental/setQuotes", quotes);
      this.timeToBegin = timeToBegins;
      this.timeToEnd = timeToEnd;
      this.times = times;
    },
    updateTimes() {
      // console.log(this.timeToBegin);
      this.day_times = dayTimes.filter((x, y) => {
        return (
          y % this.unit_time == 0 &&
          (this.timeToBegin ? this.timeToBegin <= x.value : true) &&
          (this.timeToEnd ? this.timeToEnd >= x.value : true)
        );
      });
      // console.log(this.timeToBegin);
    }
  }
};
</script>

<style>
.app_td_week_with {
  max-width: 11.4%;
}
</style>
