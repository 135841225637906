<template>
  <div>
    <div class="row mb-2">
      <div class="col">
        <app-switch v-model="overLoad">Sobre agenda</app-switch>
      </div>
      <div class="col">
        <app-datepicker
          v-model="currentDate"
          @input="setTime($event)"
        ></app-datepicker>
      </div>
      <div class="col">
        <select-employee ref="selEmp" v-model="employee_id"></select-employee>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <week-form
          ref="weekForm"
          @ortControl="
            $refs.elModalOrtCtrl.show();
            $refs.elOrtControl.load($event);
          "
          :overLoad="overLoad"
          :employee_id="employee_id"
        ></week-form>
      </div>
    </div>

    <app-modal-basic ref="elModalOrtCtrl">
      <!-- <ort-control
        @submit="
          $refs.elModalOrtCtrl.hide();
          addQueryParam('to_make', )
        "
        ref="elOrtControl"
      ></ort-control> -->
    </app-modal-basic>
  </div>
</template>
<script>
import weekForm from "./week-form";
import selectEmployee from "../../admin-module/employees/Select";
// import ortControl from "../agenda/ortControl";
import { routeMix } from "../../base-module";
import { myMoment } from "../../utils/myMoment";

export default {
  mixins: {
    routeMix
  },
  components: {
    weekForm,
    selectEmployee,
    // ortControl
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    employee_id: null,
    overLoad: false,
    currentDate: myMoment().format("YYYY-MM-DD")
  }),

  computed: {
    //
  },

  watch: {
    employee_id(to) {
      this.$router.push({
        path: "/dental-clinic/weekly-agenda/" + to,
        query: { ...this.$route.query, date: undefined }
      });
    }
  },

  mounted() {
    //
  },

  methods: {
    setTime(date) {
      this.$refs.weekForm.setCurrentTime(myMoment(date).toDate(), true);
    },
    setEmployeeId(employee_id) {
      this.$refs.selEmp.setValueFromId(employee_id);
    }
  }
};
</script>

<style></style>
